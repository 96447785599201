import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class AboutUsProvider extends HttpRequest {
  getLastItem () {
    this.setHeader(getAuthToken())
    return this.get('/cms/about-us')
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cms/about-us', payload)
  }
}

export default AboutUsProvider
