<template>
  <v-container fluid>
    <v-form
      ref="form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg">
            <v-col cols="12">
              <div class="d-flex flex-row justify-space-between">
                <h3>
                  ทำไมต้อง all to trips
                </h3>
                <v-btn
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  style="width: fit-content; background-color: white;"
                  @click="addItem()">
                  <span>
                    เพิ่มหัวข้อ
                  </span>
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
              <v-divider class="mt-3" />
            </v-col>
            <v-col
              v-for="(item, i) in formData"
              :key="`about-us-${i}`"
              class="px-10 pb-10"
              cols="12">
              <v-row class="elevation-3 rounded">
                <v-col cols="12">
                  <div class="mb-1">
                    หัวข้อที่ {{ i + 1 }}
                  </div>
                  <v-text-field
                    v-model="formData[i].title"
                    placeholder="รวมทุกทัวร์ จากโฮลเซลมืออาชีพในไทย โดยตรง"
                    color="primary"
                    outlined
                    dense
                    hide-details
                    required
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <div class="mb-1">
                    คำอธิบาย
                  </div>
                  <editor
                    v-model="formData[i].content"
                    :disabled="loading" />
                </v-col>
                <v-col cols="12">
                  <div
                    class="d-flex justify-end"
                    style="gap: 1rem;">
                    <v-btn
                      color="primary"
                      outlined
                      depressed
                      :ripple="false"
                      style="width: fit-content; background-color: white;"
                      :disabled="loading"
                      @click="addSubItem(i)">
                      <v-icon>
                        mdi-plus-circle-outline
                      </v-icon>
                      <span>
                        เพิ่มหัวข้อย่อย
                      </span>
                    </v-btn>
                    <v-btn
                      v-if="formData.length > 1"
                      color="error"
                      outlined
                      depressed
                      :ripple="false"
                      style="width: fit-content; background-color: white;"
                      :disabled="loading"
                      @click="removeItem(i)">
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                      <span>
                        ลบหัวข้อ
                      </span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  v-if="item.subContents.length"
                  cols="12">
                  <v-row>
                    <v-col
                      v-for="(sub, s) in item.subContents"
                      :key="`about-us-${i}-sub-${s}`"
                      class="px-6 pt-6 pb-7"
                      cols="12">
                      <v-row class="elevation-3 rounded">
                        <v-col cols="12">
                          <div class="mb-1">
                            หัวข้อย่อยที่ {{ s + 1 }}
                          </div>
                          <v-text-field
                            v-model="formData[i].subContents[s].title"
                            placeholder="รวมทุกทัวร์ จากโฮลเซลมืออาชีพในไทย โดยตรง"
                            color="primary"
                            outlined
                            dense
                            hide-details
                            required
                            :disabled="loading" />
                        </v-col>
                        <v-col cols="12">
                          <div class="mb-1">
                            คำอธิบาย
                          </div>
                          <editor
                            v-model="formData[i].subContents[s].content"
                            :disabled="loading" />
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="d-flex justify-end"
                            style="gap: 1rem;">
                            <v-btn
                              color="error"
                              outlined
                              depressed
                              :ripple="false"
                              style="width: fit-content; background-color: white;"
                              :disabled="loading"
                              @click="removeSubItem(i, s)">
                              <v-icon>
                                mdi-trash-can-outline
                              </v-icon>
                              <span>
                                ลบหัวข้อย่อย
                              </span>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Editor from '@/components/Editor.vue'
import AboutUsProvider from '@/resources/about-us.provider'

const AboutUsService = new AboutUsProvider()

export default {
  components: {
    Editor
  },
  data: () => ({
    loading: false,
    formData: [
      {
        title: '',
        content: '',
        subContents: []
      }
    ]
  }),
  mounted () {
    this.getItem()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'Contents' })
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await AboutUsService.getLastItem()

        this.formData = data ? data.contents : this.formData
      } catch (error) {
        console.error('getItem', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        if (this.formData.some((v) => !v.content)) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            type: 'error',
            timeout: 2000
          })

          return
        }

        await AboutUsService.updateItem({ contents: this.formData })

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไข ข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItem()
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    addItem () {
      this.formData.push({
        title: '',
        content: '',
        subContents: []
      })
    },
    removeItem (index) {
      this.formData.splice(index, 1)
    },
    addSubItem (index) {
      this.formData[index].subContents.push({
        title: '',
        content: ''
      })
    },
    removeSubItem (parent, index) {
      this.formData[parent].subContents.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
